import { Routes } from "@angular/router";

export const profileRoutes: Routes = [
  {
    path: "",
    loadComponent: () => import("./profile-wrapper.component").then((c) => c.ProfileWrapperComponent),
    children: [
      {
        path: "",
        title: "MY_PROFILE.TITLE",
        loadComponent: () => import("./pages/profile/profile.component").then((c) => c.ProfileComponent),
      },

    ],
  }
];
